import SiteHeader from '@hsl-fi/site-header'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import {
  BENEFICIARY_LOGIN_URL,
  COMPANY_ADMIN_LOGIN_URL,
  SUPERVISOR_LOGIN_URL,
} from '../../modules/anonymous/constants'
import ROUTES, {
  AUTHENTICATION,
  BENEFICIARY,
  COMPANY_ADMIN,
  ROOT,
  SELF_SERVICE,
  SUPERVISOR,
  getRouteGroupByPathname,
  multilingualRoutesToPath,
  swapLanguagePrefix,
} from '../../modules/routes/routes'
import config from '../config'
import { LANGUAGES } from '../constants'
import apiStore from '../stores/apiStore'
import headerStore from '../stores/headerStore'
import userStore from '../stores/userStore'

const Header = () => {
  const [t, i18n] = useTranslation()
  const { companyId } = useParams()
  const location = useLocation()
  const { pathname } = useMatch({ path: location.pathname })
  const routeGroup = getRouteGroupByPathname(pathname)
  const navigate = useNavigate()
  const isAuthenticated = Boolean(userStore.user.name)

  const homeUrl = isAuthenticated
    ? companyId
      ? multilingualRoutesToPath(
          ROUTES.COMPANY_ADMIN.COMPANY_PORTAL,
          { companyId },
          {},
          i18n.language
        )
      : '/authenticated'
    : '/'

  useEffect(() => {
    const fetchBannerMessages = async () => {
      try {
        await headerStore.fetchBanners(i18n.language)
      } catch (e) {
        // Fail silently
      }
    }

    fetchBannerMessages()
  }, [i18n.language])

  const languageOptions = LANGUAGES.map((language) => ({
    name: language.code,
    url: swapLanguagePrefix(location, language.code),
  }))

  const logoutRedirect = userStore.isSupervisor
    ? multilingualRoutesToPath(ROUTES.SUPERVISOR.LANDING, {}, {}, i18n.language)
    : '/'

  return (
    <SiteHeader
      hslFiUrl={config.HSL_HOME_URL}
      mainMenuOverride={[
        {
          name: t('COMMON:HSL_SITE_HEADER.COMPANY_PORTAL'),
          url: homeUrl,
          onClick: (e) => {
            e.preventDefault()
            navigate(homeUrl)
          },
        },
      ]}
      userTypeMenuOverride={[
        {
          name: t('COMMON:HSL_SITE_HEADER.FOR_INDIVIDUALS'),
          url: t('COMMON:HSL_SITE_HEADER.FOR_INDIVIDUALS_URL'),
        },
        {
          name: t('COMMON:HSL_SITE_HEADER.FOR_COMPANIES'),
          url: t('COMMON:HSL_SITE_HEADER.FOR_COMPANIES_URL'),
          isSelected: true,
        },
      ]}
      suggestionsApiUrl={`${config.HSL_CONTENT_URL}/api/v1/search/suggestions`}
      lang={i18n.language}
      languageMenu={languageOptions}
      userMenu={
        [COMPANY_ADMIN, SUPERVISOR].includes(routeGroup)
          ? {
              isLoading: apiStore.isFetchingUser,
              isAuthenticated,
              loginUrl: getLoginUrlByRouteGroup(routeGroup),
              initials:
                (userStore.user?.given_name?.[0] ?? '') +
                (userStore.user?.family_name?.[0] ?? ''),
              menuItems: [
                userStore.isCompanyAdmin && {
                  name: t('COMMON:MY_PROFILE'),
                  url: multilingualRoutesToPath(
                    ROUTES.COMPANY_ADMIN.MY_PROFILE,
                    { companyId },
                    {},
                    i18n.language
                  ),
                },
                {
                  name: t('COMMON:AUTHENTICATION.LOG_OUT'),
                  url: `/logout?redirectTo=${logoutRedirect}`,
                },
              ].filter((i) => i),
            }
          : undefined
      }
      banners={headerStore.banners}
    />
  )
}

const getLoginUrlByRouteGroup = (routeGroup) => {
  const companyAdminLoginUrl = `${COMPANY_ADMIN_LOGIN_URL}?redirectTo=authenticated`
  const supervisorLoginUrl = `${SUPERVISOR_LOGIN_URL}?redirectTo=authenticated`

  const routeGroupToLoginUrlMap = {
    [AUTHENTICATION]: companyAdminLoginUrl,
    [SUPERVISOR]: supervisorLoginUrl,
    [COMPANY_ADMIN]: companyAdminLoginUrl,
    [ROOT]: companyAdminLoginUrl,
    [SELF_SERVICE]: companyAdminLoginUrl,
    [BENEFICIARY]: BENEFICIARY_LOGIN_URL, // not really supported at the moment since beneficiaries aren't meant to sign in except in the beneficiary flow
  }

  return routeGroupToLoginUrlMap[routeGroup] ?? companyAdminLoginUrl
}

export default observer(Header)
