import PropTypes from 'prop-types'
import {
  CUSTOMER_TYPE_BUSINESS_TRIP,
  CUSTOMER_TYPE_COMMUTER_BENEFIT,
  DOMICILE_CLASS_HSL,
  DOMICILE_CLASS_NEIGHBORING_CITIES,
  DOMICILE_CLASS_NEIGHBORING_LIMITED,
  DOMICILE_CLASS_NEIGHBORING_SPECIAL,
  REACT_APP_ENV_DEV,
  REACT_APP_ENV_LOCAL,
  REACT_APP_ENV_PRODUCTION,
  REACT_APP_ENV_UAT,
} from './constants'
import { formatAddressOnSingleLine } from './format'

export const hasUserVerifiedEmail = (email, profile) =>
  (profile ? profile.emails : [])
    .filter(($) => $.verified) // include verified emails only
    .map(($) => $.address) // produce a list of email addresses as strings
    .includes(email)

export const hasUserStronglyIdentified = (user) => {
  const field = 'https://oneportal.trivore.com/claims/strong_identification'

  return user[field] ? user[field].identified : false
}

export const isDomicileWithinHsl = (domicileClasses) => {
  const allowedClasses = [
    DOMICILE_CLASS_HSL,
    DOMICILE_CLASS_NEIGHBORING_LIMITED,
    DOMICILE_CLASS_NEIGHBORING_CITIES,
    DOMICILE_CLASS_NEIGHBORING_SPECIAL,
  ]

  return domicileClasses.some((domicileClass) =>
    allowedClasses.includes(domicileClass)
  )
}

export const getFullAddress = (profile) => {
  if (!profile) {
    return undefined
  }

  const [firstLegalAddress] = profile.legalAddresses

  return firstLegalAddress
    ? formatAddressOnSingleLine(
        firstLegalAddress.streetAddress,
        firstLegalAddress.postalCode,
        firstLegalAddress.locality
      )
    : ''
}

const environment = process.env.REACT_APP_ENV

export const isLocal = () => environment === REACT_APP_ENV_LOCAL
export const isDev = () => environment === REACT_APP_ENV_DEV
export const isUat = () => environment === REACT_APP_ENV_UAT
export const isProduction = () => environment === REACT_APP_ENV_PRODUCTION

export const CustomerTypePropType = PropTypes.oneOf([
  CUSTOMER_TYPE_COMMUTER_BENEFIT,
  CUSTOMER_TYPE_BUSINESS_TRIP,
])
